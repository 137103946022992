import { v4 as uuidv4 } from "uuid";

function musicData() {
    return [
        {
            name: "Daylight",
            cover:
                "https://chillhop.com/wp-content/uploads/2020/07/ef95e219a44869318b7806e9f0f794a1f9c451e4-1024x1024.jpg",
            artist: "Aiguille",
            audio: "https://mp3.chillhop.com/serve.php/?mp3=9272",
            color: ["#EF8EA9", "#ab417f"],
            id: uuidv4(),
            active: true,
            internetRadio: false,
        },
        {
            name: "Keep Going",
            cover:
                "https://chillhop.com/wp-content/uploads/2020/07/ff35dede32321a8aa0953809812941bcf8a6bd35-1024x1024.jpg",
            artist: "Swørn",
            audio: "https://mp3.chillhop.com/serve.php/?mp3=9222",
            color: ["#4B0D4C", "#CD607D"],
            id: uuidv4(),
            active: false,
            internetRadio: false,
        },
        // Internet Radio Stations
        {
            name: "95.5 Charivari",
            artist: "128",
            audio: "https://rs5.stream24.net",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Antenne Bayern",
            artist: "128",
            audio: "https://channels.webradio.antenne.de/antenne",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Antenne Bayern - 80er Kulthits",
            artist: "96",
            audio: "https://channels.webradio.antenne.de/80er-kulthits",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Antenne Bayern - Black Beatz",
            artist: "96",
            audio: "https://channels.webradio.antenne.de/black-beatz",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Antenne Bayern - Chillout",
            artist: "96",
            audio: "https://channels.webradio.antenne.de/chillout",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Antenne Bayern - Classic Rock Live",
            artist: "96",
            audio: "https://channels.webradio.antenne.de/classic-rock-live",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Antenne Bayern - Hits fuer Kids",
            artist: "96",
            audio: "https://channels.webradio.antenne.de/hits-fuer-kids",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Antenne Bayern - Lovesongs",
            artist: "96",
            audio: "https://channels.webradio.antenne.de/lovesongs",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Antenne Bayern - Oldies",
            artist: "96",
            audio: "https://channels.webradio.antenne.de/oldies-but-goldies",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Antenne Bayern - Schlager",
            artist: "96",
            audio:
                "https://channels.webradio.antenne.de/das-schlager-karussell",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Antenne Bayern - Top 40",
            artist: "128",
            audio: "https://channels.webradio.antenne.de/top-40",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "bigFM",
            artist: "128",
            audio:
                "https://streams.bigfm.de/bigfm-deutschland-128-aac?usid=0-0-H-A-D-30",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "BigRig FM",
            artist: "128",
            audio: "https://radio.bigrig.fm",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "BlackBeats.FM",
            artist: "128",
            audio: "https://stream.blackbeats.fm",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Bremen Eins",
            artist: "128",
            audio:
                "https://rb-bremeneins-live.cast.addradio.de/rb/bremeneins/live/mp3/128/stream.mp3",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Bremen Vier",
            artist: "128",
            audio:
                "https://rb-bremenvier-live.cast.addradio.de/rb/bremenvier/live/mp3/128/stream.mp3",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Energy Berlin",
            artist: "128",
            audio: "http://energyradio.de/berlin",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Energy Bremen",
            artist: "128",
            audio: "http://energyradio.de/bremen",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Energy Hamburg",
            artist: "128",
            audio: "http://energyradio.de/hamburg",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Energy Muenchen",
            artist: "128",
            audio: "http://energyradio.de/muenchen",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Energy Stuttgart",
            artist: "128",
            audio: "http://energyradio.de/stuttgart",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "eRadio One",
            artist: "192",
            audio: "http://stage-blue-high.eradio-one.de:62005/;",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "FFH Hit Radio",
            artist: "128",
            audio: "https://mp3.ffh.de/radioffh/hqlivestream.mp3",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "HardBase FM",
            artist: "128",
            audio: "https://mp3.hb-stream.net",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "HouseTime FM",
            artist: "128",
            audio: "https://mp3.ht-stream.net",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "HR Info",
            artist: "128",
            audio:
                "https://hr-hrinfo-live.cast.addradio.de/hr/hrinfo/live/mp3/128/stream.mp3",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "HR1",
            artist: "128",
            audio:
                "https://hr-hr1-live.cast.addradio.de/hr/hr1/live/mp3/128/stream.mp3",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "HR2",
            artist: "128",
            audio:
                "https://hr-hr2-live.cast.addradio.de/hr/hr2/live/mp3/128/stream.mp3",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "HR3",
            artist: "128",
            audio:
                "https://hr-hr3-live.cast.addradio.de/hr/hr3/live/mp3/128/stream.mp3",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "HR4",
            artist: "128",
            audio:
                "https://hr-hr4-live.cast.addradio.de/hr/hr4/live/mp3/128/stream.mp3",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "JACK FM",
            artist: "128",
            audio: "https://20423.live.streamtheworld.com/JACK_FM_SC",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "laut.fm/herrmerkt-radio",
            artist: "128",
            audio: "https://stream.laut.fm/herrmerktradio",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "laut.fm/travel-trends",
            artist: "128",
            audio: "https://stream.laut.fm/travel-trends",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Majesty Internet Radio-Sacred",
            artist: "128",
            audio: "https://primary.moodyradiostream.org/majesty.mp3",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "MDR Jump",
            artist: "128",
            audio:
                "https://mdr-jump-live.cast.addradio.de/mdr/jump/live/mp3/128/stream.mp3",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "MDR Sputnik",
            artist: "128",
            audio:
                "https://mdr-sputnik-live.cast.addradio.de/mdr/sputnik/live/mp3/128/stream.mp3",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "NDR 1 Niedersachsen",
            artist: "128",
            audio:
                "https://ndr-ndr1niedersachsen-hannover.cast.addradio.de/ndr/ndr1niedersachsen/hannover/mp3/128/stream.mp3",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "NDR 1 Radio MV",
            artist: "128",
            audio:
                "https://ndr-ndr1radiomv-rostock.cast.addradio.de/ndr/ndr1radiomv/rostock/mp3/128/stream.mp3",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "NDR 1 Welle Nord",
            artist: "128",
            audio:
                "https://ndr-ndr1wellenord-kiel.cast.addradio.de/ndr/ndr1wellenord/kiel/mp3/128/stream.mp3",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "NDR 2",
            artist: "128",
            audio:
                "https://ndr-ndr2-hamburg.cast.addradio.de/ndr/ndr2/hamburg/mp3/128/stream.mp3",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "NDR 90,3 Hamburg",
            artist: "128",
            audio:
                "https://ndr-ndr903-hamburg.cast.addradio.de/ndr/ndr903/hamburg/mp3/128/stream.mp3",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "NDR Info",
            artist: "128",
            audio:
                "https://ndr-ndrinfo-hamburg.cast.addradio.de/ndr/ndrinfo/hamburg/mp3/128/stream.mp3",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "NDR Kultur",
            artist: "128",
            audio:
                "https://ndr-ndrkultur-live.cast.addradio.de/ndr/ndrkultur/live/mp3/128/stream.mp3",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "N-JOY",
            artist: "128",
            audio:
                "https://ndr-njoy-live.cast.addradio.de/ndr/njoy/live/mp3/128/stream.mp3",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "PsyRadio Progressive",
            artist: "128",
            audio: "http://81.88.36.42:8010/;",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Radio BOB!",
            artist: "192",
            audio: "https://streams.radiobob.de/bob-live/mp3-192/mediaplayer",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "RauteMusik #ChartHits-Top40",
            artist: "192",
            audio: "https://Christmas-high.RauteMusik.FM",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "RauteMusik #BIGCITYBEATS",
            artist: "192",
            audio: "https://BCB-high.RauteMusik.FM",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "RauteMusik #DeutschRap",
            artist: "192",
            audio: "https://Deutschrap-high.RauteMusik.FM",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "RauteMusik #DRUMSTEP",
            artist: "192",
            audio: "https://DrumStep-high.RauteMusik.FM",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "RauteMusik #HARDER",
            artist: "192",
            audio: "https://HardeR-high.RauteMusik.FM",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "RauteMusik #HOUSE",
            artist: "192",
            audio: "https://House-high.RauteMusik.FM",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "RauteMusik #ORIENTAL",
            artist: "192",
            audio: "https://Oriental-high.RauteMusik.FM",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "RauteMusik #TECHHOUSE",
            artist: "192",
            audio: "https://Progressive-high.RauteMusik.FM",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "RauteMusik #TRANCE",
            artist: "192",
            audio: "https://Trance-high.RauteMusik.FM",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "SimLiveRadio",
            artist: "128",
            audio: "https://stream.laut.fm/simliveradio",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "simulatorONE",
            artist: "128",
            audio: "https://stream.laut.fm/simulator1",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Sunshine Live",
            artist: "192",
            audio:
                "https://stream.sunshine-live.de/live/mp3-192/stream.sunshine-live.de/",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Sunshine Live 2000er",
            artist: "192",
            audio:
                "https://stream.sunshine-live.de/2000er/mp3-192/stream.sunshine-live.de/",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Sunshine Live House",
            artist: "192",
            audio: "http://sunshinelive.hoerradar.de/sunshinelive-house-mp3-hq",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Sunshine Live EDM",
            artist: "192",
            audio: "http://sunshinelive.hoerradar.de/sunshinelive-edm-mp3-hq",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Sunshine Live Trance",
            artist: "192",
            audio:
                "http://sunshinelive.hoerradar.de/sunshinelive-trance-mp3-hq",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Sunshine Live Hard",
            artist: "192",
            audio: "http://sunshinelive.hoerradar.de/sunshinelive-hard-mp3-hq",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Sunshine Live Techno",
            artist: "192",
            audio:
                "http://sunshinelive.hoerradar.de/sunshinelive-techno-mp3-hq",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Sunshine Live Lounge",
            artist: "192",
            audio:
                "http://sunshinelive.hoerradar.de/sunshinelive-lounge-mp3-hq",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Sunshine Live 90er",
            artist: "192",
            audio: "http://sunshinelive.hoerradar.de/sunshinelive-90er-mp3-hq",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Sunshine Live Festival",
            artist: "192",
            audio:
                "http://sunshinelive.hoerradar.de/sunshinelive-festival-mp3-hq",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Sunshine Live Drum 'n Base",
            artist: "192",
            audio: "http://sunshinelive.hoerradar.de/sunshinelive-dnb-mp3-hq",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Sunshine Live Handsup",
            artist: "192",
            audio:
                "http://sunshinelive.hoerradar.de/sunshinelive-handsup-mp3-hq",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Sunshine Live Mayday",
            artist: "192",
            audio:
                "http://sunshinelive.hoerradar.de/sunshinelive-mayday-mp3-hq",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Sunshine Live Nature One",
            artist: "192",
            audio:
                "http://sunshinelive.hoerradar.de/sunshinelive-natureone-mp3-hq",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "Sunshine Live Timewarp",
            artist: "192",
            audio:
                "http://sunshinelive.hoerradar.de/sunshinelive-timewarp-mp3-hq",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "TechLiveRadio",
            artist: "128",
            audio: "https://stream.laut.fm/techliveradio",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "TechnoBase.FM",
            artist: "192",
            audio: "https://mp3.tb-stream.net",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "TranceBase.FM",
            artist: "192",
            audio: "https://mp3.trb-stream.net",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "TruckersFM",
            artist: "128",
            audio: "https://radio.truckers.fm",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        {
            name: "YouFM",
            artist: "128",
            audio:
                "https://hr-youfm-live.cast.addradio.de/hr/youfm/live/mp3/128/stream.mp3",
            id: uuidv4(),
            active: false,
            internetRadio: true,
        },
        //ADD MORE HERE
    ];
}

export default musicData;
